import React, { Fragment, useState } from 'react';
// Import Material Design UI Components
import { TextField, Typography, Modal, Backdrop, Fade, 
        makeStyles, ListItem, ListItemText, ListItemIcon, 
        IconButton, SvgIcon, MenuItem, Select, InputLabel, FormControl, Button } from '@material-ui/core';
        
// Material-UI styles
const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
    height: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    justifyContent: 'center',
  },
  listItemTextPrimary: {
    fontSize: theme.spacing(1.85),
    color: theme.palette.text.primary,
  },
  listItemTextSecondary: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.text.secondary,
  },
  listItemName: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemCount: {
    marginLeft: theme.spacing(1)
  },
  completedButton: {
    color: theme.palette.primary.main
  },
  // Modal styles
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    justifyContent: 'space-between'
  },
  modalItemCount: {
    margin: theme.spacing(1.5)
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: 500
  },
  modalSelect: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  modalSaveButton: {
    marginLeft: theme.spacing(1)
  }
}));

const ListItemTemplate = ({index, item, onCloseNoteModal, onUpdateStoreCategories, deleteItem, completeItem, category, storesWithCategories}) => {
  
  const initialStoreCategories = {
    itemName: item.name,
    stores: [].constructor(storesWithCategories?.length).fill({storeName: '', category: ''}, 0, storesWithCategories?.length)
  };

  const [openNote, setOpenNote] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [storeCategories, setStoreCategory] = useState(initialStoreCategories);
  const [itemCount, setItemCount] = useState(item.count);
  const [itemNote, setItemNote] = useState();

  const openNoteModal = () => {
    setOpenNote(true);
    setItemNote(item.note)
  };

  const closeNoteModal = () => {
    setOpenNote(false);
    onCloseNoteModal(item, itemCount, itemNote);
  };

  const onEditNote = (event) => {
    setItemNote(event.target.value);
  }

  const openCategoryModal = () => {
    setOpenCategory(true);
  };

  const closeCategoryModal = (event) => {
    event.target.innerText === 'CANCEL' ? setStoreCategory(initialStoreCategories) : onUpdateStoreCategories(storeCategories);
    setOpenCategory(false);
    setStoreCategory(initialStoreCategories); // to ensure that all list items are reset
  };

  const incrementItemCount = (count) => {
    setItemCount(count + 1);
  }

  const decrementItemCount = (count) => {
    setItemCount(count - 1);
  }

  const handleSelectChange = (i, event) => {
    const updatedObject = { 
      ...storeCategories, 
      stores: storeCategories.stores.map((category, index) => index === i ? category = {storeName: storesWithCategories[i].name, category: event.target.value} : category)
    };
    setStoreCategory(updatedObject);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const itemCategorized = (item) =>
    storesWithCategories.filter(store => store.categories)
      .map(store => store.categories.map(category => category.items.find(listItem => listItem.toLowerCase().match(`^${item.toLowerCase()}$`))))
      .map(arr => arr.find(res => res));
      
  const classes = useStyles();

  return (
    <ListItem component="li" className={classes.listItem} button key={index}>
      {/* Item name, count and notes */}
      <ListItemText 
        disableTypography
        onClick={() => openNoteModal() }
        className={classes.listItemText}>
        <div className={classes.listItemName}>
          <Typography className={classes.listItemTextPrimary}>{item.name}</Typography>
          {item.count > 1 
            ? <Typography className={`${classes.listItemTextSecondary} ${classes.listItemCount}`}>({item.count})</Typography>
            : null
          }
        </div>
        <Typography className={classes.listItemTextSecondary}>{item.note}</Typography>
      </ListItemText>

      {/* Icons */}
      {category?.category === 'Uncategorized Items'
        ? <ListItemIcon>
            <IconButton aria-label = 'edit' onClick={() => openCategoryModal()}>
              <SvgIcon>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>        </SvgIcon>
            </IconButton>
          </ListItemIcon>
        : null
      }

      <ListItemIcon onClick={deleteItem.bind(this, item, 'items')}>
        <IconButton aria-label = 'trash' >
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
          </SvgIcon>

        </IconButton>
      </ListItemIcon>

      <ListItemIcon onClick={completeItem.bind(this, item)}>
        <IconButton  aria-label="item acquired">
          <SvgIcon className={classes.completedButton}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
          </SvgIcon>
        </IconButton>
      </ListItemIcon>

      {/* Note modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openNote}
        onClose={() => closeNoteModal()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openNote} >
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <Typography variant='h5' color="textPrimary">
                {item.name}
              </Typography>
              <div>
              <IconButton onClick={() => itemCount === 1 ? null : decrementItemCount(itemCount)} aria-label="decrement count">
                <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
                </SvgIcon>
              </IconButton>
              <Typography className={classes.modalItemCount} variant="inherit" color="textPrimary">
                {itemCount}
              </Typography>
              <IconButton onClick={() => incrementItemCount(itemCount)} aria-label="increment count">
                <SvgIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
                </SvgIcon>
              </IconButton>
              </div>
            </div>
            <form>
              <TextField
                id="standard-adornment-weight"
                multiline
                type='text'
                variant = "filled"
                rows="2"
                fullWidth
                value={ itemNote }
                placeholder="Enter note"
                onChange={ (e) => onEditNote(e) }
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  'aria-label': 'add note',
                }}
              />
            </form>
          </div>
        </Fade>
      </Modal>

      {/* Set categories modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCategory}
        onClose={() => { 
          closeCategoryModal()
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openCategory} >
          <div className={classes.paper}>
            <div>
              <Typography variant='h6' color="textPrimary" className={classes.modalHeader}>
                Set categories for: {item.name}
              </Typography>
              <div>
              {storesWithCategories?.map((store, i) => {
                return (
                  <Fragment key={i}>
                    <FormControl fullWidth className={classes.modalSelect}>
                      <InputLabel id={store.name}>{itemCategorized(item.name)[i] ? 'Already in ' + store.name : store.name}</InputLabel>
                      <Select
                        disabled={itemCategorized(item.name)[i]}
                        labelId={store.name}
                        id={store.name}
                        value={storeCategories.stores[i]?.category}
                        onChange={(evt) => handleSelectChange(i, evt)}
                        label={store.name}
                        MenuProps={MenuProps}
                      >
                      {store.categories.filter(category => !category.category.includes('Uncategorized')).map((cat, j) => {
                        return (
                        <MenuItem 
                          value={cat.category}
                          key={j}
                          >
                          <span>{cat.category}</span>
                        </MenuItem>
                        )
                      })}
                      </Select>
                    </FormControl>
                  </Fragment>
                )})}
              </div>
              <div className={classes.modalButtons}>
                <Button onClick={(e) => closeCategoryModal(e)} variant='text' color='primary'>
                  Cancel
                </Button>
                <Button onClick={(e) => closeCategoryModal(e)} className={classes.modalSaveButton} variant='outlined' color='primary'>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </ListItem>
  )
}

export default ListItemTemplate;

import React from 'react';
import { Box } from '@material-ui/core';
import "./styles/TopNavigationTitle.css";

const TopNavigationTitle = ({category}) => {
  return (
    <Box fontSize={20} flexGrow={1} textAlign="left" color="text.primary">
      <h1 className="app-title">{category}</h1>
    </Box>
  )
}

export default TopNavigationTitle
import React, { Component } from 'react';
import './App.css';
// Import Components
import SearchArea from '../components/misc/SearchArea';
import GroceryLists from '../components/listComponents/GroceryLists';
import CompletedList from '../components/listComponents/CompletedList';
import EmptyList from '../components/listComponents/EmptyList';
import TopNavigation from '../components/topNavigation/TopNavigation';
import TopNavigationTitle from '../components/topNavigation/TopNavigationTitle';
import TopNavigationCategoryDisplay from '../components/topNavigation/TopNavigationCategoryDisplay';
import TopNavigationToggleDarkTheme from '../components/topNavigation/TopNavigationToggleDarkTheme';
import TopNavigationFaves from '../components/topNavigation/TopNavigationFaves';
import FixedScroll from '../components/misc/FixedScroll';
import ErrorBoundary from '../components/misc/ErrorBoundary';
import LoadingScreen from '../components/loading/LoadingScreen';
import ReloadingMessage from '../components/loading/ReloadingMessage';
import MessageSnackbar from '../components/misc/MessageSnackbar';
// Import Material Design UI Custom Theme API
import {  Box, withStyles } from '@material-ui/core';

const styles = theme => ({
  app: {
    background: theme.palette.background.default,
    textAlign: 'center',
    height: '100%',
  },
  groceriesContainer: {
    background: theme.palette.background.paper,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
})

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiURL: process.env.REACT_APP_ENV || process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API_URL : 'http://localhost:3000',
      appIsLoading: window.sessionStorage.getItem("loadStatus") || "first load",
      items: [], // populated from back-end, see componentDidMount method
      completedItems: [], // populated from back-end, see componentDidMount method
      favoriteItems: [], // populated from back-end, see componentDidMount method
      groceriesTemplate: [], // populated from back-end, see componentDidMount method
      groceryStoreModel: [],
      formField: '',
      category: window.localStorage.getItem('category') || '',
      autocompleteIsOpen: false,
      snackbarMessages: undefined
    }
    this.onCompleteItem = this.onCompleteItem.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.onRecoverItem = this.onRecoverItem.bind(this);
    this.addToList = this.addToList.bind(this);
    this.onCloseNoteModal = this.onCloseNoteModal.bind(this);
    this.onCloseAutocomplete = this.onCloseAutocomplete.bind(this);
    this.autocompleteCheckFormField=this.autocompleteCheckFormField.bind(this);
  }

  // Methods
  // On mount, get items, completed items, and top ten favorite items
  componentDidMount() {
    fetch(`${this.state.apiURL}/`)
    .then(response => response.json())
    .then(response =>{
      if(!response.statusCode) {
        this.setState({
          items: response.items,
          completedItems: response.completedItems,
          favoriteItems: response.favoriteItems,
          groceriesTemplate: response.groceriesTemplate,
          groceryStoreModel: response.groceryStoreModel,
          category: window.localStorage.getItem('category') || 'Order Entered',
          appIsLoading: null,
        });
      } else this.fireSnackbar(response.errorMessage);
    });
    window.sessionStorage.setItem('loadStatus', 'reloading')
  }

  // Generic add grocery method
  addToList = (addedItem) => {
    const duplicateItem = this.state.items.find(grocery => grocery.name.toLowerCase() === addedItem.toLowerCase());
    if(duplicateItem) this.fireSnackbar('Item already in list.')
    else {
      if(this.currentStoreHasItemCategories() && !this.isInGroceryStoreModel(addedItem)) this.fireSnackbar('Added item is uncategorized.')

      fetch(`${this.state.apiURL}/additem`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({name: addedItem})
      })
      .then(response => response.json())
      .then(response => {
        if (!response.statusCode) this.setState({items: [].concat(...this.state.items, response.addedItem[0])});
        else this.fireSnackbar(response.errorMessage);
      });
    }
  }

  // Helper method when adding a new item to the list
  isInGroceryStoreModel(itemName) {
    const itemList = this.currentStoreHasItemCategories()?.categories.reduce((bag, category) => bag.concat(category.items), []);
    return itemList?.find(item => item.toLowerCase() === itemName.toLowerCase());
  }

  // Filter out the stores that don't have categories
  storesWithCategories() {return this.state.groceryStoreModel.filter(store => store.categories);}
  currentStoreHasItemCategories() {return this.storesWithCategories().find(store => store.name === this.state.category)}
  
  fireSnackbar(message) {
    this.setState({snackbarMessages: { message: message, time: new Date().getTime()}})
  }

  // Listen to search area input for the searchform component
  onFormChange = (event) => {
    this.setState({formField: event.target.value})
  }

  // When selecting item from autocomplete, add grocery item and clear form
  onChangeAutocomplete = (event, value, reason) => {
    // If selected value null, nothing happens
    if (reason === "blur") {
      this.setState({formField: ''})
      this.setState({autocompleteIsOpen: false})
      return
    } else if (value === null ) {
      return
    }
    //Add selected value to list
    this.addToList(value)
    //Empty form
    this.setState({formField: ''})
  } 

  onCloseAutocomplete = (event, reason) => {
    if(reason === "escape" || reason === "select-option"){
      this.setState({autocompleteIsOpen: false})
    } 
  } 

  autocompleteCheckFormField = (event) => {
    if (this.state.formField === '') {
      this.setState({autocompleteIsOpen: false})
    } 
    else{this.setState({autocompleteIsOpen: true})
  }
  }

  // On 'enter' add grocery item
  onFormSubmit = (event) => {
    event.preventDefault();
    if (this.state.formField === '') {
      return;
    }
    this.addToList(this.state.formField.charAt(0).toUpperCase(0) + this.state.formField.slice(1))
    this.setState({formField: ''})
  }

  // Checks grocery list for matches with favorite items
  // Toggles checkbox in Favorite Items modal depending on grocery list content
  // Adds/deletes items to the list
  faveCheckChildElement = (event) => {
    let favoriteItems = this.state.favoriteItems
    let stateItems = new Set(this.state.items.map(el=>el.name.toLowerCase()));
    //Toggle checkbox
    favoriteItems.forEach(item => {
      if (item.name === event.target.value)
      item.isChecked =  event.target.checked
    })
    //Search grocery list and add/remove items accordingly
    favoriteItems.forEach(item => {
      let faveLowerCase = item.name.toLowerCase()
      if(item.isChecked && !stateItems.has(faveLowerCase)) {
        this.addToList(item.name)
      } else if (!item.isChecked && stateItems.has(faveLowerCase)) {
        const itemFromState = this.state.items.find(i => i.name.toLowerCase() === item.name.toLowerCase());
        this.onDeleteItem(itemFromState, 'items');
      }
    }) 
  }

  // Acquire grocery item, move item from active to completed list
  onCompleteItem = (completedItem) => {
    fetch(`${this.state.apiURL}/completeitem`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        item: completedItem,
      })
    })
    .then(response => response.json())
    .then(response => {
      if(!response.statusCode) {
        const completedItem = this.state.items.find(item => item.id === response.completedItemId);
        this.setState({
          items: this.state.items.filter(item => item.name !== completedItem.name),
          completedItems: [].concat(...this.state.completedItems, completedItem),
        });
      } else this.fireSnackbar(response.errorMessage);
    });
  }

  // Fully delete item from whichever list it is in 
  onDeleteItem = (deletedItem, listName) => {
    fetch(`${this.state.apiURL}/deleteitem`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        id: deletedItem.id,
        listName: listName
      })
    })
    .then(response => response.json())
    .then(response => {
      if(!response.statusCode) {
        const listName = response.listName === 'items' ? 'items' : 'completedItems'
        this.setState({[listName]: this.state[listName].filter(item => item.name !== deletedItem.name)});
      } else this.fireSnackbar(response.errorMessage);
    });
  }

  // Readd item from completed list to grocery list
  onRecoverItem = (recoveredItem) => {
    fetch(`${this.state.apiURL}/recoveritem`,{
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        item: recoveredItem,
      })
    })
    .then(response => response.json())
    .then(response => {
      if(!response.statusCode) {
        this.setState({
          items: [].concat(...this.state.items, response.recoveredItem[0]),
          completedItems: this.state.completedItems.filter(item => item.name !== response.recoveredItem[0].name),
        });
      } else this.fireSnackbar(response.errorMessage);
    });
  }

  // Remove all completed items from completed List
  onDeleteAllCompleted = () => {
    fetch(`${this.state.apiURL}/deleteallcompleted`,{
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
    })
    .then(response => response.json())
    .then(response => {
      if(!response.statusCode) {
        this.setState({completedItems: response.completeditems});
      } else this.fireSnackbar(response.errorMessage);
    });
  }

    // Readd all completed items to grocery list
  onRecoverAllCompleted = () => {
    fetch(`${this.state.apiURL}/recoverallcompleted`,{
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
    })
    .then(response => response.json())
    .then(response => {
      if(!response.statusCode) {
        this.setState({
          items: response.items,
          completedItems: response.completedItems,
        });
      } else this.fireSnackbar(response.errorMessage);
    });
  }

    // Modal close method for grocery list component
    // Saves note to database
    onCloseNoteModal = (item, newCount, newNote) => {
    fetch(`${this.state.apiURL}/updateitem`,{
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        id: item.id,
        count: newCount,
        note: newNote,
      })
    })
    .then(response => response.json())
    .then(response => {
      if(!response.statusCode) {
        this.setState({items: this.state.items.map(item => item.name === response.updatedItem[0].name ? item = response.updatedItem[0] : item)})
      } else this.fireSnackbar(response.errorMessage);
    });
  };

  // When user categorizes uncategorized items, server updates grocery store model
  onUpdateStoreCategories = (obj) => {
    const filteredObj = { ...obj, stores: obj.stores.filter(store => store.category.length > 0)}

    if(filteredObj.stores.length >= 1) {
      fetch(`${this.state.apiURL}/updatestorecategories`,{
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(filteredObj)
      })
      .then(response => response.json())
      .then(response => {
        if (!response.statusCode) {
          this.setState({groceryStoreModel: response.updatedModel});
          this.fireSnackbar('Item has been categorized.');
        }
        else this.fireSnackbar(response.errorMessage);
      });
    }
  }

  // Category menu handle to change category or grocery store
  onCategoryChange = (route) => {
    this.setState({category: route});
    // Web API property that saves the category in the browser storage
    // Data is saved across browser sessions
    window.localStorage.setItem('category', `${route}`)
  }
  
  // Render
  render () {
    const { classes } = this.props;
    const { autocompleteIsOpen, category, favoriteItems, 
      formField, items, completedItems, groceriesTemplate,
      groceryStoreModel, appIsLoading, snackbarMessages } = this.state;
    return (
       <div className={classes.app}>
          <ErrorBoundary>
            {appIsLoading === "first load" 
              ? <LoadingScreen />
              : <>
                <FixedScroll>
                  <TopNavigation>
                    <TopNavigationTitle
                      category = {category}
                    />
                    <TopNavigationToggleDarkTheme/>
                    <TopNavigationCategoryDisplay 
                      category = {category}
                      groceryStoreModel = {groceryStoreModel}
                      onCategoryChange = {this.onCategoryChange}
                    />
                    <TopNavigationFaves 
                      items = {items}
                      favoriteItems = {favoriteItems}
                      faveCheckChildElement = {this.faveCheckChildElement}
                    />
                  </TopNavigation>
                </FixedScroll>
                <Box className={'Padding-box'}>
                  <Box className={`${classes.groceriesContainer} Groceries-container` }>
                    <SearchArea
                      formChange = {this.onFormChange}
                      formSubmit = {this.onFormSubmit}
                      formField = {formField}
                      groceriesTemplate = {groceriesTemplate}
                      autocompleteSelectValue = {this.onAutocompleteSelectValue}
                      closeAutocomplete = {this.onCloseAutocomplete}
                      checkFormField = {this.autocompleteCheckFormField}
                      autocompleteIsOpen = {autocompleteIsOpen}
                      changeAutocomplete = {this.onChangeAutocomplete}
                    />
                    <GroceryLists 
                      category = { category }
                      groceryStoreModel = { groceryStoreModel }
                      onCloseNoteModal = { this.onCloseNoteModal }
                      onUpdateStoreCategories = { this.onUpdateStoreCategories }
                      groceryItems = { items } 
                      completeItem = {this.onCompleteItem}
                      deleteItem = {this.onDeleteItem}
                      items = {items}
                      storesWithCategories = {this.storesWithCategories()}
                    />
                  </Box>
                  <Box className={'Completed-container'}>
                    { items.length === 0 && completedItems.length === 0 && appIsLoading !== "reloading" && <EmptyList /> }
                    { appIsLoading === "reloading" && <ReloadingMessage /> }
                    <CompletedList 
                      completedItems = { completedItems }
                      deleteItem = {this.onDeleteItem}
                      recoverItem = {this.onRecoverItem}
                      deleteallcompleted = {this.onDeleteAllCompleted}
                      recoverallcompleted = {this.onRecoverAllCompleted}
                    />
                  </Box>
                </Box>
                {snackbarMessages && <MessageSnackbar snackbarMessages = {snackbarMessages}/>}
              </>
              }
          </ErrorBoundary>
      </div>
    );
  }
}

export default withStyles(styles)(App);
